import React from 'react';

import {
    LocaleProvider,
    ConfigProvider,
    Modal,
    Form,
    message,
    Input,
    Icon,
    DatePicker,
    Row, Col,
    Spin
} from 'antd';

import "./FollowForm.css"
import { withRouter } from "react-router-dom";


import Global from '../models/Global'
import ja_JP from 'antd/lib/locale-provider/ja_JP';
import moment from 'moment';
import 'moment/locale/ja';

const db = Global.firestore;

const format = require('string-format');
format.extend(String.prototype, {});
const localize = Global.localize;
moment.locale('ja');


class FollowForm extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            visible: false,
            loading: false,
        };

        this.show = this.show.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    update(){
        // var visitors =  db.collection('visitors');

        // db.runTransaction(function(transaction) {

    }

    show() {

        var visitorId = this.props.match.params.visitor;
        this.setState({
            loading: true,
            visible: true
        }, (e) => {

            if (visitorId) {
                var visitors = db.collection('visitors');

                visitors.doc(visitorId).get().then((snap) => {

                    if (snap.exists) {
                        this.setState({loading: false,data:snap.data()});
                    }
                    else {
                        this.setState({
                            loading: false,
                            visible: false
                        }, (e) => {
                            message.warn(localize.VisitorNotFound);
                        });
                    }
                }).catch((error)=>{
                    message.warn(error.toString());
                });
            }
        });
}

renderLine(title, value) {
    return (
        <Row>
            <Col span={4} style={{ textAlign: "right", fontWeight: "bold" }}>{title}</Col>
            <Col span={20} style={{ paddingLeft: "2vw" }}>{value}</Col>
        </Row>
    )

}

onConfirm()
{
    const self = this;
    this.setState({ visible: false }, (e) => {
        self.props.history.push('/main');
    });
}

onCancel()
{
    const self = this;

    this.setState({ visible: false }, (e) => {
        self.props.history.push('/main');
    });
}

renderVisitor() {
    return (
        <div>
            {this.renderLine("会社名", "株式会社テスト")}
            {this.renderLine("訪問者", "テスト太郎")}
            {this.renderLine("予約番号", "9809")}
            {this.renderLine("メモ", "テストテストテストテストテストテストテストテストテストテストテストテストテスト")}
            {this.renderLine("担当者", "テスト次郎")}
        </div>
    );
}

renderLoading() {
    return (
        <Spin />
    );
}

render() {

    return (
        <ConfigProvider locale={ja_JP}>

            <Modal
                transitionName="am-slide-down"
                destroyOnClose={true}
                closable={true}
                maskClosable={false}
                onCancel={this.onCancel}
                visible={this.state.visible}
                title={localize.Follow} bodyStyle={{ padding: "16px", display: "flex", flexDirection: "column" }}>

                {this.state.loading ? this.renderLoading() : this.renderVisitor()}

            </Modal>
        </ConfigProvider>
    );
}
}



export default withRouter(FollowForm);
