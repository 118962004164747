import React from 'react';

import {
    Layout, Menu, Icon, Button, Card, Spin, Empty
} from 'antd';
import queryString from 'query-string';
import "./MainForm.css"
import Global from '../models/Global'

import NumGenForm from './NumGenForm'
import VisitorList from "./VisitorList"
import WatingList from "./WaitingList"
import HistoryList from "./HistoryList"

import FollowForm from './FollowForm'
import { unregister } from '../serviceWorker';

import moment from 'moment';

moment.locale('ja');

const {
    Header, Content, Footer, Sider,
} = Layout;
const SubMenu = Menu.SubMenu;

const MenuKey = {
    Visitor: "Visitor",
    VisitorWaiting: "VisitorWaiting",
    VisitorList: "VisitorList",
    VisitorHistory: "VisitorHistory",
    Profile: "Profile",
    Logout: "Logout"
};

const db = Global.firestore;
const localize = Global.localize;

export default class MainForm extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showNumGen: false,
            showNumber: false,
            number: '',
            loading: true,
            visitors: [],
            collapsed: false,
            visitorId: null,
            flgHandle: null,
            displayName: null
        }

        var numGenForm = null;
        var numMsgBox = null;

        this.state.currentMenuKey = MenuKey.VisitorList;

        this.onShowNumGen = this.onShowNumGen.bind(this);
        this.onGotNumber = this.onGotNumber.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.onNumberGenCancel = this.onNumberGenCancel.bind(this);
        this.onNumberGenConfirm = this.onNumberGenConfirm.bind(this);
        this.renderNumberGen = this.renderNumberGen.bind(this);
        this.onShowFollowForm = this.onShowFollowForm.bind(this);
        this.onMenuSelected = this.onMenuSelected.bind(this);
        this.onCollapse = this.onCollapse.bind(this);
        this.onClick = this.onClick.bind(this);

        this.route = this.route.bind(this);
    }

    route(user, params, visitorId) {
        const self = this;
        var action = params.action;

        if (action) {
            switch (action) {
                case "follow":
                    {
                        var visitorId = params.extra;
                        this.setState(
                            {
                                loading: false,
                            }, (e) => {
                                self.onShowFollowForm();
                            });
                    }
                    break;

                case "waiting":
                    {
                        this.setState({
                                flgHandle: 0,
                                displayName: user.displayName,
                                visitorId: visitorId,
                                loading: false,
                                currentMenuKey: MenuKey.VisitorWaiting
                            });
                    }
                    break;

                case "taiou":
                    {
                        this.setState({
                                flgHandle: 1,
                                displayName: user.displayName,
                                visitorId: visitorId,
                                loading: false,
                                currentMenuKey: MenuKey.VisitorWaiting
                            });
                    }
                    break;
            }
        }
        else {
            this.setState({ displayName: user.displayName, loading: false });
        }
    }

    componentDidMount() {
        const { params } = this.props.match;
        const { visitor } = queryString.parse(this.props.location.search);

        var auth = Global.auth;
        var firebase = Global.firebase;
        // this.setState({ loading: true });
        var self = this;

        firebase.auth().onAuthStateChanged(function (user) {
            process.nextTick(() => {
                if (!user) {
                    self.props.history.push("/login");
                }
                else {
                    self.route(user, params, visitor);
                }
            });

        });

    }

    onShowNumGen() {
        this.editor.show();
    }

    onNumberGenConfirm() {

    }

    onNumberGenCancel() {
    }

    renderNumberGen() {
        return (
            <NumGenForm
                key="VisitorCreator"
                wrappedComponentRef={el => this.editor = el}
                onConfirm={this.onNumberGenConfirm}
                onCancel={this.onNumberGenCancel}
                editMode={false}
                title={localize.Numbering} />
        );
    }

    onShowFollowForm() {
        this.follow.show();
    }

    onMenuSelected = ({ item, key, selectedKeys }) => {
        this.setState({ currentMenuKey: key });
    }

    renderFollowForm() {
        return (
            <FollowForm
                key="Follow"
                wrappedComponentRef={el => this.follow = el}
                title={localize.Follow} />
        )
    }

    onGotNumber(data) {

        data.number.get().then(snap => {
            const msgBox = this.numMsgBox;
            var tmp = snap.data();
            msgBox.setCancelButton(false);
            msgBox.setTitle("新規作成");
            msgBox.setMessage("予約番号は「" + tmp.value + "」になります。");
            msgBox.onShow();
        });
    }

    renderLoading() {
        return (
            <div style={{ display: "flex", flexDirection: "column", margin: "64px" }}>
                <Spin size="large" />
            </div>
        );
    }

    onLogout() {
        localStorage.clear();
        const auth = Global.auth;
        auth.signOut().then((e) => {
            this.props.history.push("/login");
            unregister();
        });
    }

    onCollapse() {
        this.setState({ collapsed: !this.state.collapsed });
    }

    onClick() {
        this.setState({
            visitorId: null,
            flgHandle: 0
        })
        this.props.history.push("/main");
    }

    renderAddVisitorButton = () => (
        <div style={{ display: "flex", flexDirection: "row-reverse" }} >
            <Button
                icon="file-add"
                type="primary"
                onClick={this.onShowNumGen}>
                {localize.Numbering}
            </Button>
        </div>)

    renderMain() {
        const auth = Global.auth;

        return (
            <div>
                <Layout style={{ minHeight: '100vh' }}>
                    <Sider
                        breakpoint="lg"
                        collapsible
                        onBreakpoint={(broken) => { console.log(broken); }}
                        collapsed={this.state.collapsed}
                        onCollapse={this.onCollapse}>

                        <div className="logo" />
                        <Menu theme="dark"
                            defaultSelectedKeys={[this.state.currentMenuKey]}
                            defaultOpenKeys={[MenuKey.Visitor]}
                            mode="inline"
                            onSelect={this.onMenuSelected}>
                            <SubMenu key={MenuKey.Visitor} title={<span><Icon type="team" />  <span>{localize.Visitor}</span></span>}>
                                <Menu.Item key={MenuKey.VisitorWaiting} onClick={this.onClick}><Icon type="coffee" /><span>{localize.VisitorWaiting}</span> </Menu.Item>
                                <Menu.Item key={MenuKey.VisitorList} onClick={this.onClick}><Icon type="ordered-list" /><span>{localize.VisitorList}</span></Menu.Item>
                                <Menu.Item key={MenuKey.VisitorHistory} onClick={this.onClick}> <Icon type="database" /> <span>{localize.VisitorHistory}</span> </Menu.Item>
                            </SubMenu>

                            <Menu.Item key={MenuKey.Profile} onClick={this.onClick}>
                                <Icon type="profile" />
                                <span>{localize.Profile}</span>
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout>
                        <Header style={{
                            background: 'rgb(233,236,239)',
                            padding: 0,
                            borderColor: 'rgb(220,222,226)',
                            display: 'flex',
                            flexDirection: 'row-reverse'
                        }}>
                            <Menu
                                theme="light"
                                mode="horizontal"
                                style={{ lineHeight: '63px', background: 'rgb(233,236,239)' }}>
                                <SubMenu title={<span><Icon type="user" />{this.state.displayName}</span>}>
                                    <Menu.Item key={MenuKey.Logout} onClick={this.onLogout} >
                                        <Icon type="logout" />
                                        {localize.Logout}
                                    </Menu.Item>
                                </SubMenu>
                            </Menu>
                        </Header>
                        <Content>
                            {this.renderNumberGen()}
                            {this.renderFollowForm()}
                            <Card style={{ margin: "16px" }}
                                extra={this.state.currentMenuKey === MenuKey.VisitorList ? this.renderAddVisitorButton() : null}>
                                {this.state.currentMenuKey === MenuKey.VisitorList ?
                                    (<VisitorList />) : null
                                }

                                {this.state.currentMenuKey === MenuKey.VisitorWaiting ?
                                    (<WatingList visitorId={this.state.visitorId} flgHandle={this.state.flgHandle} />) : null
                                }

                                {this.state.currentMenuKey === MenuKey.VisitorHistory ?
                                    (<HistoryList />) : null
                                }

                                {this.state.currentMenuKey === MenuKey.Profile ?
                                    (<Empty description="この画面は現在開発中です。パスワードの変更はログイン画面の”パスワードを忘れた方へ”をクリックし変更してください" />) : null
                                }
                            </Card>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                            {localize.Copyright}
                        </Footer>
                    </Layout>
                </Layout>
            </div>
        )
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderMain();
    }
}
