import React from 'react';

import {
    Table,
    Spin,
    Button,
    Icon
} from 'antd';

import NumGenForm from "./NumGenForm";

import Global from '../models/Global';

import "./Common.css";
import moment from 'moment';

const db = Global.firestore;


export default class VisitorList extends React.Component {

    state = {
        loading: false,
        visitorEditorVisible:false,
        dataSource :[]
    };

    constructor(props) {
        super(props);
        
        this.onUpdateVisitorCancel = this.onUpdateVisitorCancel.bind(this);
        this.onUpdateUserConfirm = this.onUpdateUserConfirm.bind(this);
        this.renderVisitorEditor = this.renderVisitorEditor.bind(this);
        this.reloadData = this.reloadData.bind(this);        
        this._isMounted = false;
    }

    onDateSort()
    {
        this.setState({
            sortedInfo: {
              order: 'descend',
              columnKey: 'Date',
            },
          });
    }


    onUpdateUserConfirm()
    {
        this.setState({visitorEditorVisible:false});
        this.reloadData();
    }

    onUpdateVisitorCancel()
    {
        this.setState({visitorEditorVisible:false});
    }

    onClick(record)
    {

        this.editor.show(record);



        // this.state.data = nextProps.data;
        // const { setFieldsValue } = this.props.form;

        // if(nextProps.data !== undefined)
        // {
        //     setFieldsValue(nextProps.data);
        // }

    }

    renderLoading() {
        return (
            <div style ={{display:"flex",flexDirection:"column"}}>
            <Spin size="large" />
            </div>
        );
    }

    reloadData(){
        this.setState({loading:true});
        this.readVisitors();
    }

    componentWillMount()
    {
        if(this.unsubscribe)
        {
            this.unsubscribe();
        }
    }

    async readVisitors() {

        const self = this;

        var users = await db.collection("users").get();
        var usersMap = new Object();
        if(users && users.empty === false)
        {
            for(var j=0;j<users.size;j++)
            {
                var user = users.docs[j];
                usersMap[user.id] = user.data();
            }
        }

        var ret = [];
        var visitorRefs = db.collection("visitors").where("state", "==", 0).orderBy("timestamp", "desc").limit(300);

        self.unsubscribe = visitorRefs.onSnapshot(async snapshots => {
            if (snapshots && snapshots.empty == false && this._isMounted) {
                var datas = [];

                var index = 1;
                var size = snapshots.size;

                snapshots.forEach(async (snapshot) => {
                    var data = snapshot.data();

                    var obj = data;
                    obj.id = snapshot.id;
                    obj.key = obj.id;
                    obj.index = (index++);

                    datas.push(obj);

                    if("number_value" in data)
                    {
                        obj.number = data.number_value;
                    }
                    else
                    if ("number" in data) {
                        var number = await data.number.get();
                        obj.number = number.data().value;
                    }

                    if ("timestamp" in data) {
                        obj.createTime = moment(data.timestamp.toDate()).format('YYYY/MM/DD HH:mm');
                    }

                    if ("date" in data) {
                        obj.date = moment(data.date.toDate()).format('YYYY/MM/DD HH:mm');
                    }

                    if ("sent" in data) {
                        obj.sent = data.sent;
                    }
                    else {
                        obj.sent = false;
                    }

                    if ("creator_id" in data) {
                        var userData = usersMap[obj.creator_id];
                        if (userData) {
                            obj.creatorName = userData.displayName;
                            obj.linkId = userData.linkId;
                        }
                    }

                    if((index - 1) === size)
                    {
                        self.setState({loading:false, dataSource: datas });
                    }
                });                           
            }
            else if(this._isMounted){
                self.setState({loading:false, dataSource: [] });
            }
        });
    }

    columns = [{
        title: '#',
        width: 40,
        dataIndex: 'index',
        key: 'index',
    }, {
        title: '会社名',
        dataIndex: 'company',
        key: 'company',
    },
    {
        title: '名前',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '予定訪問時間',
        width: 160,
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
        }
    },
    {
        title: '作成時間',
        width: 160,
        dataIndex: 'createTime',
        key: 'createTime',
        sorter: (a, b) => {
            return new Date(a.createTime).getTime() - new Date(b.createTime).getTime();
        }
    },
    {
        width: 150,
        title: '作成者',
        dataIndex: 'creatorName',
        key: 'creatorName',
    },
    {
        width: 100,
        title: '予約番号',
        dataIndex: 'number',
        key: 'number',
    },
    {
        width: 100,
        title: '送信済',
        dataIndex: 'sent',
        key: 'sent',
        render:(text,record) => record.sent?<Icon type="check-circle" theme="filled" style={{ color: "#27ae60" }} />:null
    },
    // {
    //     width: 120,
    //     title: '案内状況',
    //     dataIndex: 'state',
    //     key: 'state'
    // },
    {
        title: 'アクション',
        dataIndex: 'detail',
        width: 120,
        key: 'detail', render: (text, record) => <Button onClick={() => this.onClick(record)}>詳細</Button>,
    }];

    componentDidMount() {
        this._isMounted = true;
        this.reloadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
     }


    renderVisitorEditor()
    {
        return (                                
        <NumGenForm
            key="VisitorEditor"
            wrappedComponentRef={el => this.editor = el}
            onConfirm={this.onUpdateVisitorConfirm}
            onCancel={this.onUpdateVisitorCancel}
            editMode = {true}
            data={this.state.data}
            confirmTitle = "更新"
            visible={this.state.visitorEditorVisible}
            title="" />
            )
    }
    renderList()
    {
       return (
           <div>
               { this.renderVisitorEditor() }
               <Table dataSource={this.state.dataSource} columns={this.columns}    />
           </div>
       );
    }

    render() {
        return this.state.loading? this.renderLoading():this.renderList();
    }
}

