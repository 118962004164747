
import React from 'react';

import {
    Form,
    Icon,
    Input,
    Button,
    Card,
    Alert,
    Layout,
    Modal,
    message,
    Statistic
} from 'antd';

Number.prototype.pad = function(size) {
    var s = String(this);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}

const REFRESH_INTERVAL = 1000 / 2;

const format = require('string-format');
format.extend(String.prototype, {});

export default class Countup extends React.Component {

    constructor(props, context) {
        super(props, context);
    
        this.state = {};
        this.state.data = props.data;
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
    }

    componentDidMount()
    {
        this.startTimer();
    }

    componentWillUnmount()
    {
        this.stopTimer();
    }

    render() {

        if(this.state.data)
        {

            var stop = new Date();

            // 経過時間をミリ秒で取得
            var ms = stop.getTime() - this.state.data.access_date.toDate().getTime();
            var rest = Math.floor(ms % 1000);
    
            var s = Math.floor(ms / 1000);
            var h = Math.floor(s / 3600);

            var x = s-h*3600;

            var m = Math.floor(x / 60);
            s = s % 60;

            return (
                <p> {h.pad(2)}:{m.pad(2)}:{s.pad(2)} </p>
            );
        }
        
        return(<p></p>);

    }


    startTimer = () => {
        if (this.countdownId !== undefined) return;

        this.countdownId = window.setInterval(() => {
            this.forceUpdate();
        }, REFRESH_INTERVAL);
    };

    stopTimer = () => {
        clearInterval(this.countdownId);
        this.countdownId = undefined;
    };
}