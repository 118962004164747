import React from 'react';
import Global from '../models/Global';
import Countup from './Countup';
import "./Common.css";
import moment from 'moment';
import {
  Icon,
  Card,
  Spin,
  List,
  Col, Row, message
} from 'antd';
const { Meta } = Card;

const db = Global.firestore;


const listData = [];
for (let i = 0; i < 23; i++) {
  listData.push({
    href: '/main/follow/test',
    title: (<span><p>テスト太郎 </p><Countup timestamp={new Date()} /></span>),
    description: '会社',
    content: (<p>テスト</p>),
  });
}

const IconText = ({ type, text }) => (
  <span>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
);

const localize = Global.localize;


export default class WaitingList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      visitorEditorVisible: false,
      loadingKey: "",
      dataSource: [],
      data: null
    };

    this.state.flgHandle = this.props.flgHandle;
    this.state.visitorId = this.props.visitorId;
    this.renderList = this.renderList.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
    this.readWaitingVisitors = this.readWaitingVisitors.bind(this);
    this.readWaitingVisitor = this.readWaitingVisitor.bind(this);
    this.handleWaitingVisitor = this.handleWaitingVisitor.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.onClickAction = this.onClickAction.bind(this);
  }

  renderLoading() {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Spin size="large" />
      </div>);
  }

  componentWillMount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  /** TODO Security */
  async saveToMessages() {
    // var messages = db.collection("messages");
    // // @"mode":@"release",
    // // @"from_account":loginInfo[@"uid"],
    // // @"from_account_password":loginInfo[@"password"],
    // @"to_department":dispatchInfo[@"id"],
    // @"message_content":message,
    // @"company_id":loginInfo[@"company_id"],

    // messages.doc().put({to_department:})

  }

  async readWaitingVisitor() {
    const self = this;

    if (self.state.visitorId) {
      var visitorRefs = await db.collection("visitors").doc(self.state.visitorId);
      visitorRefs.onSnapshot(async snapshot => {
        if (snapshot && snapshot.exists) {
          var data = snapshot.data();
          if (data.state === 1) {
            var obj = data;
            obj.index = 1;
            obj.id = snapshot.id;
            obj.key = obj.id;
            obj.ref = snapshot.ref;

            if ("device_id" in data) {
              await db.collection("devices").doc(data.device_id).get()
              .then((device) => {
                if (device.exists) {
                  obj.deviceName = device.data().name;
                }
              });
            }

            if ("is_temp" in data) {
              obj.hasNumber = false;
              obj.creatorName = obj.assignee;
            } else if ("number" in data) {
              obj.hasNumber = true;
              var number = await data.number.get();
              obj.number = number.data().value;
              if ("timestamp" in data) {
                obj.createTime = moment(data.timestamp.toDate()).format('YYYY/MM/DD HH:mm');
              }

              if ("date" in data) {
                obj.date = moment(data.date.toDate()).format('YYYY/MM/DD HH:mm');
              }

              if ("creator_id" in data) {
                var userRefs = db.collection("users").doc(obj.creator_id);

                var userData = await userRefs.get();
                if (userData.exists) {
                  var data = userData.data();
                  obj.creatorName = data.displayName;
                  obj.linkId = data.linkId;
                }
              }
            }

            self.setState({ data: obj, loading: false });
          } else {
            self.setState({ data: null, loading: false });
          }

        } else {
          this.setState({
            data: null,
            loading: false
          }, (e) => {
            message.warn(localize.VisitorNotFound);
          });
        }
      });
    }
  }

  async readWaitingVisitors() {

    const self = this;

    var visitorRefs = await db.collection("visitors").where("state", "==", 1).orderBy("access_date", "desc");

    visitorRefs.onSnapshot(async snapshots => {

      if (snapshots && snapshots.empty == false) {
        var datas = [];

        var index = 1;
        var size = snapshots.size;

        for (var i = 0; i < size; i++) {
          var snapshot = snapshots.docs[i];

          var data = snapshot.data();

          var obj = data;
          obj.index = (index++);
          obj.id = snapshot.id;
          obj.key = obj.id;
          obj.ref = snapshot.ref;
          datas.push(obj);

          if ("device_id" in data) {
            await db.collection("devices").doc(data.device_id).get()
              .then((device) => {
                if (device.exists) {
                  obj.deviceName = device.data().name;
                }
              });
          }

          if ("is_temp" in data) {
            obj.hasNumber = false;
            obj.creatorName = obj.assignee;
          }
          else
            if ("number" in data) {
              obj.hasNumber = true;
              var number = await data.number.get();
              obj.number = number.data().value;
              if ("timestamp" in data) {
                obj.createTime = moment(data.timestamp.toDate()).format('YYYY/MM/DD HH:mm');
              }

              if ("date" in data) {
                obj.date = moment(data.date.toDate()).format('YYYY/MM/DD HH:mm');
              }

              if ("creator_id" in data) {
                var userRefs = db.collection("users").doc(obj.creator_id);

                var userData = await userRefs.get();
                if (userData.exists) {
                  var data = userData.data();
                  obj.creatorName = data.displayName;
                  obj.linkId = data.linkId;
                }
              }
            }
        }
        self.setState({ dataSource: datas, loading: false });

      } else {
        self.setState({ loading: false, dataSource: [] });
      }
    });
  }

  async handleWaitingVisitor() {
    var self = this;
    var visitorRefs = await db.collection("visitors").doc(self.state.visitorId);
    visitorRefs.onSnapshot(async docRef => {
      if (docRef && docRef.exists) {
        var data = docRef.data();
        if (data.state === 1) {
          var obj = data;
          obj.index = 1;
          obj.id = docRef.id;
          obj.key = obj.id;
          obj.ref = docRef.ref;

          if ("device_id" in data) {
            await db.collection("devices").doc(data.device_id).get()
              .then((device) => {
                if (device.exists) {
                  obj.deviceName = device.data().name;
                }
              });
          }

          if ("is_temp" in data) {
            obj.hasNumber = false;
            obj.creatorName = obj.assignee;
          } else if ("number" in data) {
            obj.hasNumber = true;
            var number = await data.number.get();
            obj.number = number.data().value;
            if ("timestamp" in data) {
              obj.createTime = moment(data.timestamp.toDate()).format('YYYY/MM/DD HH:mm');
            }

            if ("date" in data) {
              obj.date = moment(data.date.toDate()).format('YYYY/MM/DD HH:mm');
            }

            if ("creator_id" in data) {
              var userRefs = db.collection("users").doc(obj.creator_id);

              var userData = await userRefs.get();
              if (userData.exists) {
                var data = userData.data();
                obj.creatorName = data.displayName;
                obj.linkId = data.linkId;
              }
            }
          }

          self.onClickAction(obj);
        } else {
          self.setState({ loading: false });
        }
      } else {
        this.setState({
          loading: false
        }, (e) => {
          message.warn(localize.VisitorNotFound);
        });
      }
    });
  }

  componentDidMount() {
    this.setState({ loading: true });
    if (this.state.visitorId) {
      if (this.state.flgHandle === 0) {
        this.readWaitingVisitor();
      } else {
        this.handleWaitingVisitor();
      }
    } else {
      this.readWaitingVisitors();
    }
  }

  renderItem(key, value) {
    return (
      <Row>
        <Col span={7} style={{ textAlign: "right" }}>{key}</Col>
        <Col span={1} ></Col>
        <Col span={16} >{value}</Col>
      </Row>
    )
  }

  async onClickAction(e) {
    const auth = Global.auth;
    const func = Global.func;

    var currentUser = auth.currentUser;

    var groupsNotify = new Set();
    var receiver = new Set();

    if (e.receivers) {
      receiver = new Set(e.receivers);
    }

    var device = null;

    /** update the state and reset the key */
    if (e.creator_id) {
      receiver.add(e.creator_id);
    }

    if (currentUser.uid) {
      receiver.delete(currentUser.uid);
    }

    var messageContent = "";

    var companyValue = e.company;

    if (e.hasOwnProperty('company')) {
      if (!e.company) {
        companyValue = "未入力";
      }

      messageContent += "会社名：" + companyValue + "\n";

      var nameValue = e.name;
      if (!e.name) {
        messageContent += "名前：" + "未入力\n";
      }
      else {
        messageContent += "名前：" + nameValue + "様 \n";
      }
    }
    else {
      var nameValue = e.name;
      if (nameValue) {
        messageContent += nameValue + "\n";
      }
    }

    if (e.creator_id) {
      var userRefs = await db.collection("users").doc(e.creator_id).get();

      messageContent += "担当者：" + userRefs.data().displayName + "\n";
    }

    messageContent += "---------------------------\n上記、";
    messageContent += currentUser.displayName + "対応します";

    /** 個人宛て */
    if (receiver && receiver.size > 0) {
      var memberSend = await func.httpsCallable("sendPushMessage");

      var object = {
        members: [...receiver],
        message_content: messageContent,
        mode: Global.mode
      };
      memberSend(object).then((e) => {
      });
    }

    /** グループ宛て */
    if (e.device_id) {
      if (e.hasNumber) {
        var device = await db.collection("devices").doc(e.device_id).get();
        var departmentRefs = await db.collection("departments").doc("departmentInfo").get();
        var userRefs = await db.collection("users").doc(e.creator_id).get();
        departmentRefs.data().syncedData.map(item => {
          if (e.creator_id) {
            var device_group = device.data().receptionNumber.groups;
            if (device_group && device_group === item.group_id) {
              var itemName = (item.hierarchy === "" ? item.group_name : (item.hierarchy + "/" + item.group_name))
              groupsNotify.add(itemName);
            }

            if (userRefs.data().groups === item.group_id) {
              var itemName = (item.hierarchy === "" ? item.group_name : (item.hierarchy + "/" + item.group_name))
              groupsNotify.add(itemName);
            }
          }
        });

        if (device && device.exists) {
          var groupSend = await func.httpsCallable("sendPushMessage");

          var object = {
            departments: [...groupsNotify],
            message_content: messageContent,
            mode: Global.mode
          };

          groupSend(object).then((e) => {
          });
        }

      } else {
        e.group.map((value) => {
          groupsNotify.add(value);
        })

        var groupSend = await func.httpsCallable("sendPushMessage");

        var object = {
          departments: [...groupsNotify],
          message_content: messageContent,
          mode: Global.mode
        };

        groupSend(object).then((e) => {
        });
      }
    }

    var index = this.state.dataSource.indexOf(e);
    if (index != -1) this.state.dataSource.splice(index, 1);

    this.setState({ loadingKey: e.key });

    await e.ref.set(
      { state: 2, handler: currentUser.uid },
      { merge: true }).then((e) => {
        this.setState({ loadingKey: "" }, () => {
          message.info(localize.WaitingResponded);
        });

      });
    this.setState({ loading: false });
  }

  onDeleteAction(item) {
    item.ref.set({ state: 2 }, { merge: true }).then((e) => {

      var index = this.state.dataSource.indexOf(item);
      if (index != -1) this.state.dataSource.splice(index, 1);
      this.setState({ loadingKey: "" }, () => {
        message.info(localize.WaitingDeleted);
      });
    });
  }

  renderRespondedByMe(item) {
    return (
      <span onClick={(e) => {
        this.onClickAction(item);
      }} > {this.state.loadingKey == item.key ? <Icon type="loading" /> : <Icon type="like" />}<span >&nbsp;&nbsp;{localize.WaitingRespondedByMe}</span></span>
    )
  }

  renderDelete(item) {
    return (
      <span onClick={(e) => {
        this.onDeleteAction(item);
      }} > {this.state.loadingKey == item.key ? <Icon type="loading" /> : <Icon type="delete" />}<span>&nbsp;&nbsp;{localize.WaitingDelete}</span></span>
    )
  }

  renderList() {

    if (this.state.visitorId && this.state.data !== null) {
      const item = this.state.data;
      return (
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
          <Card
            key={item.key}
            style={{ width: 320, margin: 10 }}
            cover={item.video ? <div ><video style={{ backgroundColor: "black" }} width='100%' height={180} controls><source src={item.video} /></video></div> :
              <Icon type="video-camera" style={{ width: '100%', height: "180px", backgroundColor: "black", color: "gray", display: "flex", flexDirection: "column", fontSize: "80px", alignItems: "center", justifyContent: "center" }} />}
            actions={[this.renderRespondedByMe(item), this.renderDelete(item)]}
          >
            <Meta
              title={<span>{item.name} 様</span>}
            />
            <div style={{ display: "flex", flexDirection: "column", marginTop: "8px", height: "160px" }}>

              {item.hasNumber ? this.renderItem("番号" + ":",
                (<span><Icon type="check-circle" style={{ width: '24px', height: "24px", color: "#27ae60" }} />{item.number}</span>)) :
                this.renderItem("番号" + ":", (<span><Icon type="info-circle" style={{ width: '24px', height: "24px", color: "gray" }} />なし</span>))}

              {item.deviceName ? this.renderItem("デバイス" + ":", item.deviceName) : null}
              {item.company ? this.renderItem(localize.WaitingTitleCompany + ":", item.company) : null}
              {item.date ? this.renderItem(localize.WaitingTitleDate + ":", item.date) : null}
              {item.creatorName ? this.renderItem(localize.WaitingTitleCreator + ":", item.creatorName) : null}
              {this.renderItem(localize.WaitingTitleElapsedTime + ":", (<Countup data={item} />))}
            </div>
          </Card>
        </div>
      );
    } else if (this.state.dataSource && this.state.dataSource.length == 0) {

      return (<List
        itemLayout="vertical"
        dataSource={[]}
      />);
    } else {
      const listItems = this.state.dataSource.map((item) =>
        <Card
          key={item.key}
          style={{ width: 320, margin: 10 }}
          cover={item.video ? <div ><video style={{ backgroundColor: "black" }} width='100%' height={180} controls><source src={item.video} /></video></div> :
            <Icon type="video-camera" style={{ width: '100%', height: "180px", backgroundColor: "black", color: "gray", display: "flex", flexDirection: "column", fontSize: "80px", alignItems: "center", justifyContent: "center" }} />}
          actions={[this.renderRespondedByMe(item), this.renderDelete(item)]}
        >
          <Meta
            title={<span>{item.name} 様</span>}
          />
          <div style={{ display: "flex", flexDirection: "column", marginTop: "8px", height: "160px" }}>

            {item.hasNumber ? this.renderItem("番号" + ":",
              (<span><Icon type="check-circle" style={{ width: '24px', height: "24px", color: "#27ae60" }} />{item.number}</span>)) :
              this.renderItem("番号" + ":", (<span><Icon type="info-circle" style={{ width: '24px', height: "24px", color: "gray" }} />なし</span>))}

            {item.deviceName ? this.renderItem("デバイス" + ":", item.deviceName) : null}
            {item.company ? this.renderItem(localize.WaitingTitleCompany + ":", item.company) : null}
            {item.date ? this.renderItem(localize.WaitingTitleDate + ":", item.date) : null}
            {item.creatorName ? this.renderItem(localize.WaitingTitleCreator + ":", item.creatorName) : null}
            {this.renderItem(localize.WaitingTitleElapsedTime + ":", (<Countup data={item} />))}
          </div>
        </Card>
      );

      return (
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
          {listItems}
        </div>
      );
    }
  }

  render() {
    return this.state.loading ? this.renderLoading() : this.renderList();
  }
}
