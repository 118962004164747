import React from 'react';

import { withRouter } from "react-router-dom";
import { Layout, Spin } from 'antd';

import "./Common.css";
import "./Initial.css";

import Global from '../models/Global'

class Initial extends React.Component {

    componentDidMount() {

        var auth = Global.auth;
        var firebase = Global.firebase;
        this.setState({ loading: true });

        var self = this;
        firebase.auth().onAuthStateChanged(function (user) {
            process.nextTick(() => {
                if (!user) {
                    self.props.history.push("/login");
                }
                else {
                    self.props.history.push("/main");
                }
            });

        });
          

        // firebase.auth().onAuthStateChanged((user) => {

        // });
    }

    render() {
        return (
            <Layout className="Common Initial" style={{ backgroundColor: '#333' }} >

                <Spin size="large" />

            </Layout>
        )
    }
}

// ReactDOM.render(<Initial />, document.getElementById('root'))

export default withRouter(Initial);