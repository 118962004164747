import React from 'react';

import {
    LocaleProvider,
    ConfigProvider,
    Modal,
    Form,
    Icon,
    Input,
    Button,
    message,
    DatePicker,
    Select,
    Radio,
    Checkbox,
    Row,
    Col
} from 'antd';

import Global from '../models/Global'
import moment from 'moment';
import ja_JP from 'antd/lib/locale-provider/ja_JP';


import 'moment/locale/ja';
import Column from 'antd/lib/table/Column';


const Option = Select.Option;

const format = require('string-format');
format.extend(String.prototype, {});
const localize = Global.localize;
moment.locale('ja');

const { TextArea } = Input;

const db = Global.firestore;

class NumGenForm extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.mounted = false;
        this.state = {
            onClick: props.onClick,
            visible: false,
            company: '',
            name: '',
            receivers: [],
            receivers_selected: [],
            date: '',
            editMode: false,
            readonly: false,
            loading: false,
            adding: false,
            updating: false,
            deleting: false,
            onGotNumber: props.onGotNumber,
            mailSend: false
        };

        this.onClose = this.onClose.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onShow = this.onShow.bind(this);
        this.storeData = this.storeData.bind(this);
        this.onNumbering = this.onNumbering.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.renderCreateButtons = this.renderCreateButtons.bind(this);
        this.renderModifyButtons = this.renderModifyButtons.bind(this);
        this.renderViewButtons = this.renderViewButtons.bind(this);

        this.onChangeMailSend = this.onChangeMailSend.bind(this);
        this.onSendMail = this.onSendMail.bind(this);

        this.renderMailStatusFlag = this.renderMailStatusFlag.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // this.state.visible = nextProps.visible;
        this.state.title = nextProps.title;
        this.state.editMode = nextProps.editMode;
        this.state.data = nextProps.data;
    }

    onChangeMailSend(flag) {
        this.setState({ mailSend: !this.state.mailSend });
    }

    show(data, readonly) {
        const { setFieldsValue, resetFields } = this.props.form;

        this.reset();
        this.state.readonly = readonly;

        const self = this;

        this.setState({ visible: true }, () => {

            if (data) {
                if (data.date) {
                    data.date = moment(data.date);
                }
                else {
                    data.date = null;
                }
                self.state.currentId = data.id;
                self.state.mailSent = data.sent;

                setFieldsValue({
                    company: data.company,
                    name: data.name,
                    date: data.date,
                    memo: data.memo,
                    mail: data.mail,
                    receivers: data.receivers,
                });
            }
            else {
                resetFields([]);
            }
        });


    }


    onClose() {
        this.setState({ visible: false });
    }

    reset()
    {
        this.setState({
            company: '',
            name: '',
            date: '',
            memo: '',
            mail: '',
            mailSend: false,
            receivers_selected: [],
            show: true
    });
    }

    onShow() {            
        this.reset();
    }

    onChange(event) {
        // if(e === this.state.refCompany)
        // {
        //     this.setState({ company: e.target.value });
        // }
        // else if( e === this.state.refName)
        // {
        //     this.setState({name:e.target.value});
        // }
        var fieldName = event.target.name;
        this.setState({ [fieldName]: event.target.value });
    }

    onFocus() {
        window.makeDateTimePicker();
    }

    onNumbering = (e) => {
        e.preventDefault();

        const { validateFieldsAndScroll, getFieldsValue, resetFields, getFieldValue } = this.props.form;

        var self = this;
        validateFieldsAndScroll((err, values) => {
            if (!err) {

                self.setState({ loading: true, adding: true });

                var data = {};
                var value = getFieldValue("company");

                if (value) {
                    data.company = value;
                }

                value = getFieldValue("name");
                if (value) {
                    data.name = value;
                }

                value = getFieldValue("date").toDate();
                if (value) {
                    data.date = value;
                }

                value = getFieldValue("memo");
                if (value) {
                    data.memo = value;
                }

                value = getFieldValue("mail");

                if (value) {
                    data.mail = value;
                }

                data.receivers = self.state.receivers_selected;

                self.storeData(data);
            }
        });
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidMount() {
        var receivers = [];

        const func = Global.func;
        const userList = func.httpsCallable("getUserList");
        this.mounted = true;
        var self = this;
        userList().then(e => {
            (e.data).forEach(element => {
                receivers.push(<Option key={element.id} value={element.id} title={element.displayName}>{element.displayName}</Option>);
            });

            if (self.mounted) {
                self.setState({ receivers: receivers });
            }
        });

    }


    storeData(data) {
        var user = Global.auth.currentUser;

        const self = this;
        var visitors = db.collection('visitors');

        var postData = data;
        postData.creator_id = user.uid;
        postData.state = 0;

        if (data.memo) {
            postData.memo = data.memo;
        }

        visitors.add(postData).then(async function (docRef) {
            var unsubscribe = docRef.onSnapshot(snap => {

                if ("number" in snap.data()) {
                    unsubscribe();

                    snap.data().number.get().then((result) => {
                        self.setState({
                            adding: false,
                            loading: false,
                            visible: false
                        });


                        const modal = Modal.info();

                        modal.update({
                            transitionName: "am-slide-down",
                            title: localize.Numbering,
                            content: localize.NumberGot.format(result.data().value),
                            onOk: async () => {
                                if (self.state.mailSend) {
                                    await self.onSendMail(docRef.id);

                                    Modal.destroyAll();
                                }
                            }
                        });


                    });
                }
            });

            // docRef.get().then(snap => {
            //     if ("number" in snap.data()) {
            //         unsubscribe();
            //         self.setState({ loading: false });

            //         message.info("採番成功しました");
            //     }
            // });
        })
            .catch(function (error) {
                console.error("Error adding document: ", error);
            });

    }

    updateData = (data) => {
        var id = data.id;

        var visitors = db.collection('visitors');
        visitors.doc(id).set(data, { merge: true }).then((e) => {
            message.info(localize.VisitorUpdateSuccess);
            this.setState({ visible: false, loading: false, updating: false });
        }).catch((e) => {
            message.warn(localize.VisitorUpdateFaild);
            this.setState({ visible: false, loading: false, updating: false });
        });

    }

    deleteData(id) {
        var user = Global.auth.currentUser;

        const self = this;

        var visitors = db.collection('visitors');
        visitors.doc(id).delete().then((e) => {
            message.info(localize.VisitorDeleteSuccess);
            this.setState({ visible: false, loading: false, deleting: false });
        })
            .catch((e) => {

                message.warn(localize.VisitorDeleteFaild);
                this.setState({ visible: false, loading: false, deleting: false });
            });
    }

    onDelete = (e) => {
        e.preventDefault();
        var id = this.state.currentId;

        this.deleteData(id);
    }

    onModify = (e) => {
        e.preventDefault();

        const { validateFieldsAndScroll, getFieldsValue, resetFields, getFieldValue } = this.props.form;

        var self = this;
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                self.setState({ loading: true, updating: true });

                var data = {};
                data.date = getFieldValue("date").toDate();

                data.id = self.state.currentId;
                data.receivers = self.state.receivers_selected;

                var name = getFieldValue("name");
                if(name)
                {
                    data.name = name;
                }

                var company = getFieldValue("company");
                if(company)
                {
                    data.company = company;
                }
               
                var mail = getFieldValue("mail");
                if(mail)
                {
                    data.mail = mail;
                }

                var memo = getFieldValue("memo");
                if (memo) {
                    data.memo = memo;
                }

                self.updateData(data);
            }
        });
    }

    updateData(data) {
        db.updateData()
    }

    onCancel(e) {
        this.setState({ visible: false });

        if (this.state.onCancel) {
            this.state.onCancel();
        }



        Modal.destroyAll();
    }

    renderViewButtons() {
        return (<div>
            <Button onClick={this.onCancel}>{localize.Confirm}</Button>
        </div>);
    }


    renderModifyButtons() {
        return (
            <div>
                <Button type="primary" onClick={this.onModify} loading={this.state.updating} >{localize.Update}</Button>
                <Button type="danger" onClick={this.onDelete} loading={this.state.deleting}>{localize.Delete}</Button>
                <Button onClick={this.onCancel}>{localize.Cancel}</Button>
            </div>
        )
    }

    renderCreateButtons() {
        return (
            <div>
                <Button onClick={this.onNumbering} type="primary" loading={this.state.adding}>{localize.Confirm}</Button>
                <Button onClick={this.onCancel}>{localize.Cancel}</Button>
            </div>
        )
    }

    handleChange(value, object) {

        var array = [];

        for (var i = 0; i < object.length; i++) {
            array.push(object[i].key);
        }

        this.state.receivers_selected = array;
    }

    async confirmMailContent(docId) {
        const func = Global.func;

        const sendMailToVisitor = func.httpsCallable("sendMailToVisitor");

        return await sendMailToVisitor({ visitorId: docId, action: "confirm" });
    }

    onChangeMailContent(event) {
        this.mailContent = event.target.value;
    }

    async onSendMail(docId) {

        const func = Global.func;

        const sendMailToVisitor = func.httpsCallable("sendMailToVisitor");

        var self = this;
        this.setState({ loading: true }, async () => {


            var object = await sendMailToVisitor({ visitorId: docId, action: "confirm" });
            self.setState({ loading: false });

            var modal = Modal.confirm();

            var content = object.data.content;
            modal.update({
                title: object.data.subject,
                content: (
                    <TextArea onChange={self.onChangeMailContent.bind(this)} autosize={{ minRows: 4, maxRows: 20 }}
                        defaultValue={content} />
                ),
                okText: "送信",
                cancelText: "キャンセル",
                maskClosable: true,
                afterClose: () => { self.setState({ loading: false }) },
                onCancel: (e) => {
                    self.setState({ loading: false }, (e) => {
                        modal.destroy();
                    });
                },
                onOk: async () => {


                    var data = {
                        visitorId: docId,
                        action: "send"
                    };

                    if (self.mailContent) {
                        data.userContent = self.mailContent;
                    }

                    await sendMailToVisitor(data);


                    self.setState({ loading: false, mailSent: true }, () => {
                        message.info("通知メールを送信しました");
                    });

                    Modal.destroyAll();
                }
            });




            // db.collection("setting").get("common").then((e)=>{

            // })
        });

    }

    renderMailStatusFlag(isSent) {


        if (isSent) {
            return (
                <span ><Icon type="check-circle" theme="filled" style={{ color: "#27ae60" }} />
                    <span style={{ marginLeft: "6px" }}>通知メール送信済</span>
                    {
                        this.state.readonly ? null : <Button type="dashed" style={{ marginLeft: "8px" }} onClick={() => this.onSendMail(this.state.currentId)} loading={this.state.loading} >再送</Button>
                    }

                </span>
            );
        }

        return (
            <span  ><Icon type="info-circle" theme="filled" style={{ color: "gray" }} />
                <span style={{ marginLeft: "6px" }}>通知メール未送信</span>
                {this.state.readonly ? null : <Button type="dashed" style={{ marginLeft: "8px" }} onClick={() => this.onSendMail(this.state.currentId)} loading={this.state.loading} >送信</Button>}
            </span>
        );

    }



    render() {

        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const values = getFieldsValue();
        const self = this;

        return (
            <ConfigProvider locale={ja_JP}>

                <Modal
                    title={this.state.readonly ? localize.VisitorView : this.state.editMode ? localize.VisitorEdit : localize.Numbering}
                    transitionName="am-slide-down"
                    okText={this.state.editMode ? localize.VisitorUpdate : localize.Confirm}
                    onOk={this.state.editMode ? this.onModify : this.onNumbering}
                    onCancel={this.onCancel}
                    footer={this.state.readonly ? this.renderViewButtons() : this.state.editMode ? this.renderModifyButtons() : this.renderCreateButtons()}
                    cancelText={localize.Cancel}
                    maskClosable={false}
                    destroyOnClose={true}
                    visible={this.state.visible} >

                    <Form>

                        <Form.Item>
                            {getFieldDecorator('company', {
                                rules: [
                                    {
                                        required: true, message: localize.PleaseInputCompany
                                    }],
                            })(
                                <Input
                                    prefix={<Icon type="team" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    key="inputCompany"
                                    type="text"
                                    name="company"
                                    disabled={this.state.loading || this.state.readonly}
                                    placeholder="会社名" />)}

                        </Form.Item >

                        <Form.Item >
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true, message: localize.PleaseInputName
                                    }],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    key="inputName"
                                    type="text"
                                    name="name"
                                    disabled={this.state.loading || this.state.readonly}
                                    placeholder="名前"
                                    addonAfter="様"
                                />
                            )}
                        </Form.Item >

                        <Form.Item >
                            {getFieldDecorator('mail', {
                                rules: [
                                    {
                                        required: false
                                    }],
                            })(
                                <Input
                                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    key="inputMail"
                                    type="text"
                                    name="mail"
                                    disabled={this.state.loading || this.state.readonly}
                                    placeholder="メールアドレス"
                                />
                            )}
                        </Form.Item >

                        <Form.Item>
                            {getFieldDecorator('receivers', {
                                rules: [
                                    {
                                        required: false
                                    }],
                            })(
                                <Select
                                    suffixIcon="user"
                                    mode="multiple"
                                    size="default"
                                    placeholder={localize.NumberingReceivers}
                                    initialValue={[]}
                                    disabled={this.state.loading || this.state.readonly}
                                    onChange={this.handleChange}
                                    optionFilterProp="title"
                                    style={{ width: '100%' }}
                                >
                                    {this.state.receivers}
                                </Select>)}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator('date', {
                                rules: [
                                    {
                                        required: true, message: localize.PleaseInputDate
                                    }],
                            })(
                                <DatePicker
                                    showTime={{ format: 'HH:mm' }}
                                    locale={ja_JP}
                                    disabled={this.state.loading || this.state.readonly}
                                    allowClear={false}
                                    format={'YYYY/MM/DD HH:mm'}
                                    placeholder={localize.VisitDate} />)}
                        </Form.Item>

                        <Form.Item style={{ marginBottom: "0px" }}>
                            {getFieldDecorator('memo', {
                                rules: [
                                    {
                                        required: false
                                    }],
                            })(
                                // 最大 1,048,487 バイト（1 MiB～89 バイト）。UTF-8 表現の最初の 1,500 バイトのみがクエリによって考慮されます。

                                <TextArea
                                    style={{ marginBottom: "12px" }}
                                    prefix={<Icon type="form" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    key="inputMemo"
                                    type="text"
                                    name="memo"
                                    autosize={{ minRows: 2, maxRows: 6 }}
                                    disabled={this.state.loading || this.state.readonly}
                                    placeholder="メモ"
                                />
                            )}

                            {
                                this.state.editMode ? this.renderMailStatusFlag(self.state.mailSent) : (
                                    <Checkbox
                                        disabled={values.mail == undefined || values.mail.length == 0 || this.state.loading}
                                        onChange={this.onChangeMailSend}>
                                        番号お知らせメール送信
                                    </Checkbox>
                                )
                            }


                        </Form.Item >


                    </Form>
                </Modal >
            </ConfigProvider>

        )
    }

}

const WrappedNumGenForm = Form.create({ name: 'number_gen' })(NumGenForm);

export default WrappedNumGenForm;
