import React, { Component } from 'react';
import LoginForm from './components/LoginForm';
import MainForm from './components/MainForm';
import Initial from './components/Initial';
import {
  BrowserRouter as Router,
  Route,
  Switch, Redirect
} from "react-router-dom";

import './App.css';
import "antd/dist/antd.css";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Initial} />
          <Route exact path="/login" component={LoginForm} />
          <Route exact path="/main" component={MainForm} />
          <PrivateRoute exact path="/main/:action" component={MainForm} />
          <Route exact path="/main/:action/:extra" component={MainForm} />
        </Switch>
      </Router>
    );
  }
}

function PrivateRoute({ component: Component, ...rest }) {
  if (localStorage.getItem('displayName') !== null) {
    return (
      <Route {...rest} render={props => (<Component {...props} />)} />
    );
  } else {
    return (
      <Route
        {...rest}
        render={props =>
          (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }
}

export default App;
