import React from 'react';

import { withRouter, Redirect } from "react-router-dom";

import {
    Form,
    Icon,
    Input,
    Button,
    Card,
    Alert,
    Layout,
    Modal,
    message,
    Spin
} from 'antd';

import "./Common.css"
import "./LoginForm.css"

import Global from '../models/Global'

const localize = Global.localize;

class LoginForm extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            value: '',
            showSendMailMsg: false,
            showSendForgotMailMsg: false,
            onLoginSuccess: props.onLoginSuccess,
            loginFailed: false,
            sentSuccess: false,
            alertMessage: "",
            loading: false,
            refMailForm: null,
            refForgotPasswordForm: null,
            redirectToReferrer: false,
            isInitializing:false
        };

        this.onLogin = this.onLogin.bind(this);
        this.onRegister = this.onRegister.bind(this);
        this.onSendMail = this.onSendMail.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renderLoginForm = this.renderLoginForm.bind(this);
        this.onForgotPassword = this.onForgotPassword.bind(this);
        this.onSendForgotPasswordMail = this.onSendForgotPasswordMail.bind(this);
    }

    componentDidMount() {
        var auth = Global.auth;
        var firebase = Global.firebase;

        var self = this;

        this.setState({ isInitializing: true });

        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                process.nextTick(() => {
                    self.props.history.push("/main");
                });
            }

            self.setState({ isInitializing: false });

        });
        // process.nextTick(()=>{
        // var user = auth.currentUser;


        // });
    }

    getValidationState() {
        const length = this.state.value.length;
        if (length > 10) return 'success';
        else if (length > 5) return 'warning';
        else if (length > 0) return 'error';
        return null;
    }

    handleChange(event) {
        var fieldName = event.target.name;
        this.setState({ [fieldName]: event.target.value });
    }

    onForgotPassword() {
        const self = this;

        if (this.refEmail.value === null || this.refEmail.value.length === 0) {

            self.setState({
                loginFailed: true,
                alertMessage: (
                    <h5><strong>アカウント名（メアド）</strong>を入力してください</h5>
                ),
                loading: false
            });
        }
        else {
            self.setState({ loading: false });
            self.refForgotPasswordForm.setMessage("「" + this.refEmail.value + "」へパスワード再発行のリンクを送信します");
            self.refForgotPasswordForm.onShow();
        }
    }

    onShowEmailVerifiedForm() {
        const modal = Modal.confirm();
        const self = this;

        modal.update({
            title: localize.EmailVerifiedTitle,
            content: localize.EmailVerifiedMessage,
            transitionName: "am-slide-down",
            okText: localize.Send,
            cancelText: localize.Cancel,
            onOk: (e) => {

                self.onSendMail();
                modal.destroy();
            },
            onCancel: (e) => {
                modal.destroy();
            }
        });
    }

    onLogin(e) {
        e.preventDefault();

        const { getFieldValue } = this.props.form;

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const self = this;

                this.setState({ loading: true });
                const auth = Global.auth;

                var email = getFieldValue("userName");
                var password = getFieldValue("password");

                auth.setPersistence('local')
                    .then(function () {
                        // Existing and future Auth states are now persisted in the current
                        // session only. Closing the window would clear any existing state even
                        // if a user forgets to sign out.
                        // ...
                        // New sign-in will be persisted with session persistence.
                        auth.signInWithEmailAndPassword(email, password)
                            .then(function (userCredential) {
                                var user = userCredential.user;
                                if (user.emailVerified === false) {
                                    self.onShowEmailVerifiedForm();
                                    self.setState({ loading: false });
                                }
                                else {
                                    localStorage.setItem('displayName', user.displayName);
                                    self.setState({ redirectToReferrer: true });
                                }
                            })
                            .catch(function (error) {
                                self.setState({
                                    loginFailed: true,
                                    alertMessage: localize.LoginErrorMessage,
                                    loading: false
                                });
                            });
                    })
                    .catch(function (error) {
                    });
            }
        });

        // var user = userCredential.user;
        // if (user.emailVerified === false) {
        //     user.sendEmailVerification().then(function () {
        //         // Email sent.
        //     }).catch(function (error) {
        //         // An error happened.
        //     });
        // }

        // user.sendEmailVerification().then(function () {
        //     // Email sent.
        // }).catch(function (error) {
        //     // An error happened.
        // });
    }

    onRegister() {
        const firebase = Global.firebase;
        firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).catch(function (error) {
            // Handle Errors here.

            // ...
        });
    }

    onFocusPassword() {
        this.refPassword.focus();
    }

    onSendForgotPasswordMail() {

        const { getFieldValue } = this.props.form;

        var email = getFieldValue("userName");

        const auth = Global.auth;
        const modal = Modal.confirm();
        const self = this;

        modal.update({
            title: localize.PasswordReset,
            content: (
                <Input ref={el => self.passwordResetEmail = el}
                    placeholder={localize.Email} defaultValue={email} />
            ),
            transitionName: "am-slide-down",
            okText: localize.Send,
            cancelText: localize.Cancel,
            onOk: (e) => {
                var emailAddress = self.passwordResetEmail.input.value;

                console.log(emailAddress);
                auth.sendPasswordResetEmail(emailAddress).then(function () {
                    message.info(localize.SentSuccess);
                }).catch(function (error) {
                    message.warn(localize.SentFailed);
                });;

                modal.destroy();
            },
            onCancel: (e) => {
                modal.destroy();
            }
        });
    }

    onSendMail() {
        const auth = Global.auth;
        var user = auth.currentUser;

        user.sendEmailVerification().then(function () {
            // Email sent.
            message.info(localize.EmailVerifiedSentSuccess);
        }).catch(function (error) {
            message.warn(localize.EmailVerifiedSentFailed);
        });
    }

    renderLoginForm() {
        const { getFieldDecorator } = this.props.form;
        let { from } = this.props.location.state || { from: { pathname: "/main" } };
        let { redirectToReferrer } = this.state;

        if (redirectToReferrer) return <Redirect to={from} />;

        return (
            <Layout className="Common LoginForm-Center" style={{ backgroundColor: '#333' }}>

                {this.state.loginFailed ? (
                    <Alert message={this.state.alertMessage}
                        style={{ marginBottom: "10px" }}
                        type="warning" />
                ) : null}

                <Card
                    title={localize.LoginFormTitle}
                    type='inner'
                    style={{ width: 300 }}
                    headStyle={{ backgroundColor: '#337ab7', color: '#FFF' }}
                    bordered={false}>

                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item style={{ marginBottom: '14px' }}>
                            {getFieldDecorator('userName', {
                                rules: [{ required: true, message: 'IDを入力してください' }],
                            })(
                                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="ID" disabled={this.state.loading} />
                            )}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '10px' }}>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'パスワードを入力してください' }],
                            })(
                                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="パスワード" disabled={this.state.loading} />
                            )}
                        </Form.Item>

                        <Form.Item style={{ marginBottom: '0px', marginTop: '0px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <a onClick={this.onSendForgotPasswordMail}>{localize.PasswordForgotLink}</a>

                                <Button type="primary" loading={this.state.loading} onClick={this.onLogin}
                                    htmlType="submit" className="login-form-button">
                                    {localize.Login}
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </Layout>
        );
    }

    renderLoading() {
        return (
            <div style={{ display: "flex", flexDirection: "column", margin: "64px" }}>
                <Spin size="large" />
            </div>
        );
    }

    render() {
        if(this.state.isInitializing)
        {
            return (this.renderLoading());
        }
        else
        {
            return (this.renderLoginForm());
        }
    }
}

const WrappedLoginForm = Form.create({ name: 'normal_login' })(LoginForm);

export default withRouter(WrappedLoginForm);