import firebase from 'firebase/app'
import 'firebase/auth'
import "firebase/firestore"
import "firebase/functions"

import config from './config'

import ja from './languages/ja'
// import en from './languages/en'

if (window.firebase === undefined) {
    firebase.initializeApp(config);

    // const firestore = ; 
    // const settings = {/* your settings... */ timestampsInSnapshots: true};
    // firestore.settings(settings);
    window.firebase = firebase;
    window.firestore = firebase.firestore();
    window.auth = firebase.auth();
    window.func = firebase.functions();

    window.mode = config.mode;
}

var global = {};
global.firebase = window.firebase;
global.firestore = window.firestore;
global.auth = window.auth;
global.func = window.func;
global.mode = window.mode;

global.localize = ja;

export default global;
