import React from 'react';

import {
    Table,
    Spin,
    Button
} from 'antd';

import Global from '../models/Global';
import NumGenForm from "./NumGenForm";

import "./Common.css";
import moment from 'moment';

const db = Global.firestore;


const localize = Global.localize;

export default class HistoryList extends React.Component {

    state = {
        loading: false,
        visitorEditorVisible:false,
        dataSource :[],
        columns : [{
            title: '#',
            width: 30,
            dataIndex: 'index',
            key: 'index',
        }, {
            title: '会社名',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: '名前',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '訪問時間',
            width: 160,
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            }
        },
        {
            width: 150,
            title: '作成者',
            dataIndex: 'creatorName',
            key: 'creatorName',
        },
        {
            width: 150,
            title: 'デバイス名',
            dataIndex: 'deviceName',
            key: 'deviceName',
        },
        {
            width:150,
            title: '対応者',
            dataIndex:'handlerName',
            key:'handlerName'
        },
        {
            width: 100,
            title: '予約番号',
            dataIndex: 'number',
            key: 'number',
        },
        // {
        //     width: 120,
        //     title: '案内状況',
        //     dataIndex: 'state',
        //     key: 'state'
        // },
        {
            title: 'アクション',
            dataIndex: 'detail',
            width: 120,
            key: 'detail', render: (text, record) => <Button onClick={() => this.onClick(record)}>詳細</Button>,
        }]
    };

    constructor(props) {
        super(props);
        
        this.onUpdateVisitorCancel = this.onUpdateVisitorCancel.bind(this);
        this.onUpdateUserConfirm = this.onUpdateUserConfirm.bind(this);
        this.renderVisitorEditor = this.renderVisitorEditor.bind(this);
        this.reloadData = this.reloadData.bind(this);        
    }

    onDateSort()
    {
        this.setState({
            sortedInfo: {
              order: 'descend',
              columnKey: 'Date',
            },
          });
    }

    componentWillMount()
    {
        if(this.unsubscribe)
        {
            this.unsubscribe();
        }
    }

    onUpdateUserConfirm()
    {
        this.setState({visitorEditorVisible:false});
        this.reloadData();
    }

    onUpdateVisitorCancel()
    {
        this.setState({visitorEditorVisible:false});
    }

    onClick(record)
    {

        this.editor.show(record,true);

        // this.state.data = nextProps.data;
        // const { setFieldsValue } = this.props.form;

        // if(nextProps.data !== undefined)
        // {
        //     setFieldsValue(nextProps.data);
        // }
    }

    renderLoading() {
        return (
            <div style ={{display:"flex",flexDirection:"column"}}>
            <Spin size="large" />
            </div>
        );
    }

    reloadData(){
        this.setState({loading:true});
        this.readVisitors();
    }

    async readVisitors() {

        const self = this;

        var ret = [];


        var visitorRefs = db.collection("visitors").where("state", "==", 2).orderBy("access_date", "desc").limit(1000);


        var devices = await db.collection("devices").get();

        var users = await db.collection("users").get();

        var showDevcies = false;
        var devicesMap = new Object();

        var usersMap = new Object();

        if(users && users.empty === false)
        {
            for(var j=0;j<users.size;j++)
            {
                var user = users.docs[j];
                usersMap[user.id] = user.data();
            }
        }

        if (devices && devices.empty === false) {
            if (devices.size > 1) {
                showDevcies = true;

                for (var j = 0; j < devices.size; j++) {
                    var device = devices.docs[j];

                    devicesMap[device.id] = device.data().name;
                }
            }
            else
            {
                var index = self.state.columns.findIndex(obj => obj.key === 'deviceName');

                if(index >= 0)
                {
                    self.state.columns.splice(index,1);
                }
            }
        }

        self.unsubscribe = visitorRefs.onSnapshot( async snapshots => {
            if (snapshots && snapshots.empty == false) {
                var datas = [];

                var index = 1;
                var size = snapshots.size;
                // debugger;

                snapshots.forEach(async (snapshot) => {
                    var data = snapshot.data();

                    var obj = data;
                    obj.index = (index++);
                    obj.id = snapshot.id;
                    obj.key = obj.id;

                    if (showDevcies && "device_id" in data) {
                        var deviceName = devicesMap[data.device_id];
                        if (deviceName) {
                            obj.deviceName = deviceName;
                        }
                        else if ("device_name" in data) {
                            /** 削除された時点で、名前を保存するように設計必要がある */
                            obj.deviceName = data.device_name;
                        }
                        else {
                            obj.deviceName = "削除済";
                        }
                    }

                    if ("timestamp" in data) {
                        obj.createTime = moment(data.timestamp.toDate()).format('YYYY/MM/DD HH:mm');
                    }

                    if ("access_date" in data) {
                        obj.date = moment(data.access_date.toDate()).format('YYYY/MM/DD HH:mm');
                    }

                    if ("creator_id" in data) {
                        var userData = usersMap[obj.creator_id]
                        // var userRefs = db.collection("users").doc(obj.creator_id);

                        // var userData = await userRefs.get();

                        if (userData) {
                            obj.creatorName = userData.displayName;
                            obj.linkId = userData.linkId;
                        }
                    }
                    else {
                        obj.creatorName = "-";
                    }

                    if (!obj.company) {
                        obj.company = "-";
                    }

                    datas.push(obj);

                    if ("number_value" in data) {
                        obj.number = data.number_value;
                    }
                    else if ("number" in data) {
                        // var number = await data.number.get();
                        // obj.number = number.data().value;
                    }
                    else {
                        obj.number = "-";
                    }

                    if ("handler" in data) {
                        var uid = data.handler;
                        var userData = usersMap[uid];

                        if (userData) {
                            obj.handlerName = userData.displayName;
                        }
                    }

                    if((index - 1) === size)
                    {
                        self.setState({ dataSource: datas,loading:false} );
                    }
                });

                // debugger;

                // self.setState({ dataSource: datas,loading:false} );
            }
            else
            {
                self.setState({ loading: false, dataSource: [] });
            }
        });
    }



    componentDidMount() {
        this.reloadData();
    }


    renderVisitorEditor()
    {
        return (                                
        <NumGenForm
            key="VisitorEditor"
            wrappedComponentRef={el => this.editor = el}
            onConfirm={this.onUpdateVisitorConfirm}
            onCancel={this.onUpdateVisitorCancel}
            editMode = {true}
            data={this.state.data}
            confirmTitle = {localize.Confirm}
            visible={this.state.visitorEditorVisible}
            title={localize.Confirm} />
            )
    }
    renderList()
    {
       return (
           <div>
               { this.renderVisitorEditor() }
               <Table dataSource={this.state.dataSource} columns={this.state.columns}    />
           </div>
       );
    }

    render() {
        return this.state.loading? this.renderLoading():this.renderList();
    }
}

